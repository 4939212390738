<i18n>
ru:
  downloadAppTitleHome: Скачайте приложение
  appDescriptionHome: Хотите первым узнавать о новых скидках и акциях, а так же получать персональные предложения?
  appAdditional: Пицца в подарок при первом заказе
ua:
  downloadAppTitleHome: Завантажте програму
  appDescriptionHome: Хочете першим дізнаватися про нові знижки та акції, а також отримувати персональні пропозиції?
  appAdditional: Піца у подарунок при першому замовленні

us:
  downloadAppTitleHome: Download the application
  appDescriptionHome: Do you want to be the first to know about new discounts and promotions, as well as receive personal offers?
  appAdditional: Free pizza with your first order
</i18n>

<template>
  <div
    v-if="appConfig.VueSettingsPreRun.MainPageAppLinkEnable"
    :class="[
      appConfig.VueSettingsPreRun.MainPageAppLinkEnable ? 'v-col-md-6' : 'v-col-12'
    ]"
  >
    <div class="v-apps__home--item">
      <div
        class="v-title"
        v-html="translate('suggestApps.downloadAppTitleHome')"
      />
      <div
        class="v-desc"
        v-html="translate('suggestApps.appDescriptionHome')"
      />
      <common-apps is-main-page />
      <div
        class="v-additional-text"
        v-html="translate('suggestApps.appAdditional')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
</script>

<style lang="scss">
@use 'assets/mixins';
@use 'assets/variables';

.v-apps {
  margin-bottom: 8px;
  &__home {
    height: 100%;
    &--item {
      padding: 20px;
      box-shadow: variables.$CardShadow;
      border-radius: variables.$BorderRadius;
      .v-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .v-desc {
        font-size: 1.14rem;
        font-weight: 500;
        margin-bottom: 12px;
      }
      .v-additional-text {
        font-size: 0.9rem;
      }
      .v-apps--link {
        flex: 0 0 calc(24% - 8px);
        max-width: calc(24% - 8px);
        @include mixins.md {
          flex: 0 0 calc(30% - 8px);
          max-width: calc(30% - 8px);
        }
        @include mixins.sm {
          flex: 0 0 calc(50% - 8px);
          max-width: calc(50% - 8px);
        }
      }
    }
  }
}
</style>
